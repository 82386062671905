exports.components = {
  "component---src-pages-cvpr-2020-tsx": () => import("./../../../src/pages/cvpr2020.tsx" /* webpackChunkName: "component---src-pages-cvpr-2020-tsx" */),
  "component---src-pages-cvpr-2021-tsx": () => import("./../../../src/pages/cvpr2021.tsx" /* webpackChunkName: "component---src-pages-cvpr-2021-tsx" */),
  "component---src-pages-cvpr-2022-tsx": () => import("./../../../src/pages/cvpr2022.tsx" /* webpackChunkName: "component---src-pages-cvpr-2022-tsx" */),
  "component---src-pages-cvpr-2023-tsx": () => import("./../../../src/pages/cvpr2023.tsx" /* webpackChunkName: "component---src-pages-cvpr-2023-tsx" */),
  "component---src-pages-cvpr-2024-tsx": () => import("./../../../src/pages/cvpr2024.tsx" /* webpackChunkName: "component---src-pages-cvpr-2024-tsx" */),
  "component---src-pages-cvpr-2025-tsx": () => import("./../../../src/pages/cvpr2025.tsx" /* webpackChunkName: "component---src-pages-cvpr-2025-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testindex-tsx": () => import("./../../../src/pages/testindex.tsx" /* webpackChunkName: "component---src-pages-testindex-tsx" */)
}

